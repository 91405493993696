
(window as any).__zone_symbol__UNPATCHED_EVENTS = [
    'backbutton',
    'deviceready'
];

(window as any).__Zone_enable_cross_context_check = true;
(window as any).__Zone_disable_requestAnimationFrame = true;


import 'zone.js/dist/zone';
import 'web-animations-js';
